<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters id="table-search" class="tou-row">
        <v-col cols="12" md="12">
          <div class="total-heading total-heading-left" style="visibility: hidden">
            <h1>TOU Management</h1>
          </div>
          <div class="filters-board">
            <v-col cols="4">
              <v-row no-gutters class="filter-group">
                <v-col cols="12" md="4" class="modal-label">
                  <span>Global:</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    ref="siteGlobalSelect"
                    :items="listGlobals"
                    v-model="global"
                    item-text="name"
                    item-value="id"
                    :class="`${global ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Global"
                    @change="handleChangeGlobal"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="filter-group pt-2">
                <v-col cols="12" md="4" class="modal-label">
                  <span>Regional:</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    ref="siteRegionalSelect"
                    :items="listRegionals"
                    v-model="regional"
                    item-text="name"
                    item-value="id"
                    :class="`${regional ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Regional"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row no-gutters class="filter-group">
                <v-col cols="12" md="4" class="modal-label">
                  <span>TOU Plan:</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    ref="touPlanSelect"
                    :items="listTouPlans"
                    v-model="touPlan"
                    item-text="name"
                    item-value="key"
                    :class="`${touPlan ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select TOU Plan"
                    @change="handleChangeTouPlan"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-form
        ref="submitForm"
        @submit.prevent="handleSubmitTou"
        autocomplete="off"
      >
        <div class="tou-settings-container">
          <div class="dropdown-columns">
            <div v-for="(tou_time, index) in tou_times.slice(0, 12)" :key="index" class="dropdowns-list">
              <v-row no-gutters class="filter-group">
                <v-col cols="12" md="4" class="modal-label">
                  <span>{{ formatTime(tou_time.time_24h) }}</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    :items="listTouHours"
                    v-model="tou_time.tou"
                    :rules="touTimesRules"
                    item-text="name"
                    item-value="key"
                    :class="{
                      'off_peak_background': tou_time.tou === 'off_peak',
                      'on_peak_background': tou_time.tou === 'on_peak'
                    }"
                    class="custom-select__selections custom-hidden-input"
                    :validate-on-blur="true"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    @change="updateChart"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="dropdown-columns">
            <div v-for="(tou_time, index) in tou_times.slice(12, 24)" :key="index + 12" class="dropdowns-list">
              <v-row no-gutters class="filter-group">
                <v-col cols="12" md="4" class="modal-label">
                  <span>{{ formatTime(tou_time.time_24h) }}</span>
                </v-col>
                <v-col cols="12" md="8">
                  <v-select
                    :items="listTouHours"
                    v-model="tou_time.tou"
                    :rules="touTimesRules"
                    item-text="name"
                    item-value="key"
                    :class="{
                      'off_peak_background': tou_time.tou === 'off_peak',
                      'on_peak_background': tou_time.tou === 'on_peak'
                    }"
                    class="custom-select__selections custom-hidden-input"
                    :validate-on-blur="true"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    @change="updateChart"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="donut-chart-container">
            <div class="donut-chart-hour">
              <VueApexCharts ref="chart24Hour" type="donut" :options="chartOptions" :series="series" />
              <div class="label-hour label-hour-00">00</div>
              <div class="label-hour label-hour-06">06</div>
              <div class="label-hour label-hour-12">12</div>
              <div class="label-hour label-hour-18">18</div>
            </div>
            <div class="donut-chart-buttons">
              <button
                class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                :ripple="false"
                type="button"
                :disabled="!isChanged"
                @click="cancelTou"
              >
                CANCEL
              </button>
              <button
                class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                :ripple="false"
                type="button"
                :disabled="!isChanged"
                @click="handleSubmitTou"
              >
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </v-form>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { touService, multipleSitesService, } from "@/services";
import VueApexCharts from "vue-apexcharts";
export default {
  components: { VueApexCharts, },
  data() {
    return {
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      listGlobals: [],
      global: "",
      listRegionals: [
        {
          "id": "all",
          "name": "ALL",
        }
      ],
      regional: "all",
      touInfo: null,
      listTouPlans: [
        {
          key: "weekdays",
          name: "Weekdays",
        },
        {
          key: "weekends_holidays",
          name: "Weekends & Holidays",
        },
      ],
      touPlan: "weekdays",
      listTouHours: [
        {
          key: "on_peak",
          name: "On Peak",
        },
        {
          key: "off_peak",
          name: "Off Peak",
        },
      ],
      tou_times: [],
      original_tou_times: [],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '40%',
              labels: {
                show: false,
              },
            },
          },
        },
        colors: [],
        labels: [],
        tooltip: {
          enabled: true,
          custom: function({ seriesIndex, w }) {
            const tou_hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
            let tou_value = '';
            const color = w.globals.colors[seriesIndex];
            if (color === '#a9d18e') {
              tou_value = 'Off-peak';
            } else if (color === '#ffd966') {
              tou_value = 'On-peak';
            }
            return '<div style="background-color: #323232; padding: 4px">' +
                '<span>' + tou_hours[seriesIndex] + (tou_value ? ' - ' + tou_value : '') + '</span>' +
              '</div>';
          }
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 0.3,
          colors: ['#323232'],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
      },
      series: Array(24).fill(1),
      touTimesRules: [
        (v) =>!!v || "TOU is required",
      ],
      selectMenuList: ["touPlanSelect"],
    };
  },
  mounted() {
    this.updateChart();
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('scroll', this.onScroll, true);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('scroll', this.onScroll, true);
  },
  async created() {
    this.tou_times = JSON.parse(JSON.stringify(this.TOU_TIMES_DEFAULT));
    this.original_tou_times = JSON.parse(JSON.stringify(this.tou_times));
    await this.getListGlobals();
    if (this.listGlobals?.length) {
      // HARD CODE
      this.listGlobals = [this.listGlobals.find(item => item.name === "Thailand") || this.listGlobals[0]];
      this.global = this.listGlobals[0]?.id;
    }
    this.handleChangeGlobal();
  },
  computed: {
    isChanged() {
      for (let i = 0; i < this.tou_times.length; i++) {
        if (this.tou_times[i].tou !== this.original_tou_times[i].tou) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    formatTime(index) {
      const hours = index.toString().padStart(2, '0');
      return `${hours}:00`;
    },
    updateChart() {
      const newColors = this.tou_times.map((tou_time) => {
        if (!tou_time.tou) {
          return '#606060';
        }
        return tou_time.tou === 'off_peak' ? '#a9d18e' : '#ffd966';
      });
      this.$refs.chart24Hour.updateOptions({
        colors: newColors
      });
    },
    async cancelTou() {
      this.tou_times = JSON.parse(JSON.stringify(this.original_tou_times));
      await this.updateChart();
    },
    handleSubmitTou() {
      if (this.touInfo?.id) {
        this.submitEdit();
      } else {
        this.submitAdd();
      }
    },
    async getListGlobals() {
      return await multipleSitesService
        .getListGlobals()
        .then((res) => {
          if (res?.data?.data) {
            const dataSiteGlobals = res.data.data;
            this.listGlobals = [...dataSiteGlobals];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    handleChangeGlobal() {
      this.getTouSettings();
      // const selectedGlobal = this.listGlobals.find(global => global.id === global_id);
      // if (selectedGlobal) {
      //   this.listRegionals = [...this.listRegionals, ...selectedGlobal.regionals];
      // }
    },
    handleChangeTouPlan() {
      this.getTouSettings();
    },
    onMouseMove(event) {
      this.handleMouseMove(event, this);
    },
    onScroll() {
      this.handleScroll(this.selectMenuList, this);
    },
    handleGetTouSettings(newTouTimes) {
      return this.tou_times.map(item => {
        let newTouTime = newTouTimes.find(newItem => newItem.time_24h === item.time_24h);
        return newTouTime ? { ...item, tou: newTouTime.tou } : item;
      });
    },
    async getTouSettings() {
      const __this = this;
      if (this.global && this.touPlan) {
        let data = { 
          global_id: this.global,
          tou_plan: this.touPlan,
        };
        await touService.getTouSettings(data).then((res) => {
          if(res?.data?.data) {
            let data = res.data.data;
            __this.touInfo = data;
            __this.tou_times = __this.handleGetTouSettings(data.tou_times);
            __this.original_tou_times = JSON.parse(JSON.stringify(__this.touInfo.tou_times));
          } else {
            __this.touInfo = null;
            __this.tou_times = JSON.parse(JSON.stringify(__this.TOU_TIMES_DEFAULT));
            __this.original_tou_times = JSON.parse(JSON.stringify(__this.TOU_TIMES_DEFAULT));
          }
        });
        await this.updateChart();
      }
    },
    async submitAdd() {
      if (this.$refs.submitForm.validate()) {
        const data = this.prepareData(
        this.global,
        this.touPlan,
        this.tou_times
      );
      await touService
        .storeTouSettings(data)
        .then((res) => {
          if (res.status !== 200) throw res;
          this.getTouSettings();
          this.apiMessage = "TOU has been successfully added";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "TOU cannot be added";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
      }
    },
    closeMessage() {
      this.messageDialog = false;
    },
    prepareData(global_id, tou_plan, tou_times) {
      const data = {
        global_id: global_id,
        tou_plan: tou_plan,
        tou_times: tou_times,
      };
      return data;
    },
    submitEdit() {
      if (this.$refs.submitForm.validate()) {
        const data = this.prepareData(
          this.global,
          this.touPlan,
          this.tou_times
        );
        touService
          .updateTouSettings(this.touInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getTouSettings();
            this.apiMessage = "TOU has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "TOU cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
  },
};
</script>
